import { useState, useEffect } from 'react';
import { useUser } from '../../../hooks/useUser'
import { AcessLayout as Container } from '../index'
import { HeaderContent, Grid, GridRow } from '../styles'
import api from '../../../services/api';
import { NewBanner } from './Modal/NewBanner';
import { EditBanner } from './Modal/EditBanner';
import { DeleteBanner } from './Modal/DeleteBanner';

export function AcessBanners() {
  const { token, host } = useUser()
  const [banners, setBanners] = useState([])
  const [banner, setBanner] = useState({})
  const [isNewBannerOpen, setIsNewBannerOpen] = useState(false)
  const [isUpdateBannerOpen, setIsUpdateBannerOpen] = useState(false)
  const [isDeleteBannerOpen, setIsDeleteBannerOpen] = useState(false)

  const handleOpenNewBannerModal = () => setIsNewBannerOpen(true)
  const handleCloseNewBannerModal = () => setIsNewBannerOpen(false)
  const handleOpenUpdateBannerModal = (banner) => {
    setIsUpdateBannerOpen(true)
    setBanner(banner)
  }
  const handleCloseUpdateBannerModal = () => setIsUpdateBannerOpen(false)
  const handleOpenDeleteBannerModal = (banner) => {
    setIsDeleteBannerOpen(true)
    setBanner(banner)
  }
  const handleCloseDeleteBannerModal = () => setIsDeleteBannerOpen(false)

  async function loadBanners(){
    try {
      const response = await api.get("banners").catch((e) =>{
        // console.log(e)
        // console.log(e.response)
      })
      setBanners(response.data)

    } catch (error) {
      console.log(error.response.data)
    }
  }

  useEffect(() => {
    loadBanners();
    if(token){
      api.defaults.headers.authorization = token
    }
  }, [token])

  return (
    <Container>
      <HeaderContent>
        <span>Banners</span>
        <button type="button" onClick={() => handleOpenNewBannerModal()}>
          <label>Adicionar</label>
        </button>
      </HeaderContent>

      <Grid>
        <GridRow>
          <h3>Id</h3>
          <h3>Imagem</h3>
          <h3>URL</h3>
          <h3>Ações</h3>
        </GridRow>
        {
          banners.map(banner => {
            return (
              <GridRow key={banner.id}>
                <span>{banner.id}</span>
                <img className="bannerImg" src={`${host}/files/${banner.root}`} alt={banner.url} style={{ objectFit: 'contain' }} />                
                <a href={banner.root} target="_blank" rel="noreferrer">{banner.root}</a>
                <div>
                  <button type="button" onClick={() => handleOpenUpdateBannerModal(banner)}>
                    <label>Editar</label>
                  </button>

                  <button type="button" onClick={() => handleOpenDeleteBannerModal(banner)}>
                    <label>Apagar</label>
                  </button>
                </div>
              </GridRow>
            )
          })
        }
      </Grid>

      <EditBanner isOpen={isUpdateBannerOpen} onRequestClose={handleCloseUpdateBannerModal} banner={banner}/>
      <DeleteBanner isOpen={isDeleteBannerOpen} onRequestClose={handleCloseDeleteBannerModal} banner={banner} />
      <NewBanner isOpen={isNewBannerOpen} onRequestClose={handleCloseNewBannerModal}/>
    </Container>
  );
}
