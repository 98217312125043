import { useState } from 'react';

import { Container, Item, ButtonNext } from './styles';

import { useCart } from '../../../../../hooks/useCart';
import history from '../../../../../services/history';
import { Delivery } from '../../../../../components/Delivery';

export function ColumnLaterals() {
  const { total } = useCart();

  const [viewDeliveryBox, setViewDeliveryBox] = useState(true);
  const [valueFrete, setValueFrete] = useState(0);
  const [daysFrete, setDaysFrete] = useState(0);
  const [selectedShipping, setSelectedShipping] = useState({});


  function handleNext() {
    const result = Number(total) + Number(valueFrete)
    localStorage.setItem('@voucolar/totalPricePlusShipment', result);
    localStorage.setItem('@voucolar/shipment', Number(valueFrete));
    history.push('/shopDetails');
  }

  return (
    <Container>
      <h2>TOTAL NO CARRINHO</h2>

      <Item>
        <span>Subtotal</span>

        <strong>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(total)}
        </strong>
      </Item>

      {viewDeliveryBox ? (
        <>
          <Item>
            <span>Valor do Frete:</span>
            <strong>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(isNaN(+selectedShipping.price) ? 0 : +selectedShipping.price)}
            </strong>

            <span>Dias para entrega:</span>
            <strong>
              {daysFrete === 0.00001
                ? 'Retirada no local'
                : `${selectedShipping.delivery_time ?? 0} Dias`}
            </strong>
          </Item>
          <Delivery 
            setDaysFrete={setDaysFrete} 
            setValueFrete={setValueFrete} 
            isButtonEnable={true}
            onSelectShipping={(shipping) => setSelectedShipping(shipping)}
          />
        </>
      ) : (
        <Item>
          <span>Entrega</span>

          <span>Digite seu endereço para ver as opções de entrega.</span>

          <span></span>

          <button type='button' onClick={() => setViewDeliveryBox(true)}>
            Calcular Frete
          </button>
        </Item>
      )}

      <Item>
        <span>Total</span>

        <strong>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(total) + Number(valueFrete))}
        </strong>
      </Item>

      <ButtonNext onClick={() => handleNext()}>FECHAR COMPRA</ButtonNext>
    </Container>
  );
}
