import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NewHeader } from "../../../components/NewHeader";
import { useCart } from "../../../hooks/useCart";
import { useUser } from "../../../hooks/useUser";

import { PermissionComponent } from "./PermissionComponent";
import Title from "../Title";

import {
  Container,
  Form,
  InfoCart,
  BoxInputDuo,
  InputSelect,
  BoxInputOne,
  BoxInputRadio,
  Item,
  InfoTitle,
  FreteItem,
  FreteItemContent,
  CreditCardInformation,
  ModalBackground,
  ModalContainer,
  Modal,
  ModalHeader,
  CreditCard,
  CardNumber,
  CardHolderName,
  ExpirationDateContainer,
  CardForm,
  CardExpirationDate,
  Input,
  InputsContainer,
  SubmitButton,
  MelhorEnvioFreteOptionsWrapper,
} from "./styles";
import { toast } from "react-toastify";
import api from "../../../services/api";
import cepApi from "../../../services/cepApi";
import InputMask from "react-input-mask";

const states = [
  { uf: "AC", name: "Acre" },
  { uf: "AL", name: "Alagoas" },
  { uf: "AP", name: "Amapá" },
  { uf: "AM", name: "Amazonas" },
  { uf: "BA", name: "Bahia" },
  { uf: "CE", name: "Ceará" },
  { uf: "DF", name: "Distrito Federal" },
  { uf: "ES", name: "Espírito Santo" },
  { uf: "GO", name: "Goiás" },
  { uf: "MA", name: "Maranhão" },
  { uf: "MT", name: "Mato Grosso" },
  { uf: "MS", name: "Mato Grosso do Sul" },
  { uf: "MG", name: "Minas Gerais" },
  { uf: "PA", name: "Pará" },
  { uf: "PB", name: "Paraíba" },
  { uf: "PR", name: "Paraná" },
  { uf: "PE", name: "Pernambuco" },
  { uf: "PI", name: "Piauí" },
  { uf: "RJ", name: "Rio de Janeiro" },
  { uf: "RN", name: "Rio Grande do Norte" },
  { uf: "RS", name: "Rio Grande do Sul" },
  { uf: "RO", name: "Rondônia" },
  { uf: "RR", name: "Roraima" },
  { uf: "SC", name: "Santa Catarina" },
  { uf: "SP", name: "São Paulo" },
  { uf: "SE", name: "Sergipe" },
  { uf: "TO", name: "Tocantins" },
];

export function DetailsCartPage() {
  const { user, token } = useUser();
  const { cart, total, clearCart } = useCart();
  let history = useHistory();
  const previousFreteValue = localStorage.getItem("@voucolar/shipment");
  const [melhorEnvioFretes, setMelhorEnvioFretes] = useState([]);
  const [pagSeguroLoaded, setPagSeguroLoaded] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [senderHash, setSenderHash] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [typeOfPeople, setTypeOfPeople] = useState("PF");
  const [namefirm, setNameFirm] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Selecionar");
  const [phone, setPhone] = useState("");
  const [comments, setComments] = useState("");

  const [addressId, setAddressId] = useState("1");
  const [addressIdDelivery, setAddressIdDelivery] = useState("");
  const [otherAddressVisible, setOtherAddressVisible] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState({});
  const [cepDelivery, setCepDelivery] = useState("");
  const [streetDelivery, setStreetDelivery] = useState("");
  const [numberDelivery, setNumberDelivery] = useState("");
  const [complementDelivery, setComplementDelivery] = useState("");
  const [neighborhoodDelivery, setNeighborhoodDelivery] = useState("");
  const [cityDelivery, setCityDelivery] = useState("");
  const [stateDelivery, setStateDelivery] = useState("Acre");

  const [storedFreteValue, setStoredFreteValue] = useState(
    window.localStorage.getItem("@voucolar/shipment")
  );
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [daysFrete, setDaysFrete] = useState("");
  const [sedexFrete, setSedexFrete] = useState();
  const [pacFrete, setPacFrete] = useState();
  const [frete, setValueFrete] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [cardToken, setCardToken] = useState("");
  const [cardExpirationDateMonth, setCardExpirationDateMonth] = useState("");
  const [cardExpirationDateYear, setCardExpirationDateYear] = useState("");
  const [selectTypeDelivery, setSelectTypeDelivery] = useState("");
  const [freteWithdraw, setFreteWithdraw] = useState(false);

  const [isCreditCardModalOpened, setIsCreditCardModalOpened] = useState(false);

  const hasPreviousFreteValue =
    previousFreteValue <= 0
      ? `${Number(total) + Number(frete)}`
      : `${Number(total)}`;

  const cardNumberFormatted = useMemo(() => {
    const value = cardNumber
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    return value;
  }, [cardNumber]);

  async function callMelhorEnvio() {
    try {
      const response = await api.post('/freight', { cep })
      setMelhorEnvioFretes(response.data.map((service) => {
        service['name'] = `${service['company']['name']} ${service['name']}`
        return service
      }));
    } catch (err) {
      console.log(err);
    }
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function handleCloseModal() {
    setIsCreditCardModalOpened(false);
  }

  async function loadFrete() {
    setIsLoading(true);

    const formattedCEP = cep.replace("-", "");

    const validationOne = formattedCEP.length === 8;
    const validationTwo = isNumber(formattedCEP);

    if (!validationOne) {
      setIsLoading(false);
      return alert("Erro na validação");
    }

    if (!validationTwo) {
      setIsLoading(false);
      return alert("Erro na validação T");
    }

    if (selectTypeDelivery === "ret") {
      setValueFrete(0.00001);
      setDaysFrete(0.00001);
    } else {
      // const sedexResponse = await api.post("melhor-envio", {
      //   cep: cep,
      //   option: "04014",
      // });

      // const pacResponse = await api.post("correios", {
      //   cep: cep,
      //   option: "04510",
      // });

      callMelhorEnvio();

      // setPacFrete({
      //   days: pacResponse.data.days,
      //   value: pacResponse.data.value,
      // });
    }

    setIsLoading(false);
  }

  async function createNewAddress(data, delivery) {
    try {
      const response = await api
        .post(`/users/${user.id}/address`, data)
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      if (delivery) {
        setAddressIdDelivery(response.data.id);
      } else {
        setAddressId(response.data.id);
      }

      return response.data.id;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }


  async function handleFinalizar() {
    if (deliveryType == "" || paymentMethod === "") {
      return toast.warn(
        "Verifique o frete ou o método de pagamento corretamente!"
      );
    }

    let addressIdDelivery,
      addressId = null;

    if (
      !name ||
      !surname ||
      !cep ||
      !street ||
      !number ||
      !neighborhood ||
      !city ||
      !state ||
      !phone ||
      !complement
    ) {
      return toast.error("Preencha todos os campos não opcionais");
    }

    if (!cpf && !cnpj) {
      return toast.error("Preencha todos os campos não opcionais");
    }

    console.log("criou endereço 1");

    if (otherAddressVisible) {
      if (
        !cepDelivery ||
        !streetDelivery ||
        !numberDelivery ||
        !neighborhoodDelivery ||
        !cityDelivery ||
        !stateDelivery ||
        !complement
      ) {
        return toast.error("Preencha todos os campos não opcionais");
      }

      addressIdDelivery = await createNewAddress(
        {
          name,
          surname,
          cep: cepDelivery,
          street: streetDelivery,
          nation: "Brasil",
          number: numberDelivery,
          neighborhood,
          state: stateDelivery,
          phone: phone
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", ""),
        },
        true
      );
      setAddressIdDelivery(addressIdDelivery);
    }

    addressId = await createNewAddress(
      {
        name,
        surname,
        cep: cep.replace("-", ""),
        cpf: cpf.replaceAll(".", "").replace("-", ""),
        cnpj: cnpj | "",
        street,
        nation: "Brasil",
        city,
        number,
        neighborhood,
        state,
        phone: phone
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", ""),
        comments: comments ? comments : "Vazio",
      },
      false
    );

    setAddressId(addressId);

    let productsIds = "";
    let customizationsIds = "";

    for (const key in cart) {
      if (Object.hasOwnProperty.call(cart, key)) {
        const element = cart[key];

        if (element.personalize) {
          if (customizationsIds.length > 1) {
            customizationsIds += ",";
          }
          customizationsIds += "Id";
          customizationsIds += "_";
          customizationsIds += element.id;
          customizationsIds += "_";
          customizationsIds += "Quantidade";
          customizationsIds += "_";
          customizationsIds += element.amount;
          customizationsIds += "_";
          customizationsIds += element.nameMark;
          customizationsIds += "_";
          customizationsIds += element.nameModel;
        }

        if (!element.personalize) {
          if (productsIds.length > 1) {
            productsIds += ",";
          }
          productsIds += element.name;
          productsIds += "_";
          productsIds += element.nameMark;
          productsIds += "_";
          productsIds += element.id;
          productsIds += "_";
          productsIds += "Quantidade";
          productsIds += "_";
          productsIds += element.amount;
        }
      }
    }

    if (paymentMethod === "boleto") {
      console.log("Produtos", cart)
      try {
        const response = await api
          .post(`/users/${user.id}/orders`, {
            addressId,
            customizationsIds: customizationsIds ? customizationsIds : null,
            addressIdDelivery: addressIdDelivery
              ? addressIdDelivery
              : addressId,
            productsIds: productsIds ? productsIds : "",
            frete,
            senderHash,
            subTotal: total,
            total: hasPreviousFreteValue,
            delivery_date: deliveryDate,
            payment_method: "boleto",
            cpf: cpf.replaceAll(".", "").replace("-", ""),
            comments: comments ? comments : "Vazio",
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser
              // and an instance of http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          });

        clearCart();

        history.push(`/shopFinish/${response.data.id}`, {
          order: response.data,
          methodPayment: paymentMethod,
        });
      } catch (error) {
        toast.error("Transação não foi aprovada, verifique os campos.");
      }
    } else {
      setIsCreditCardModalOpened(true);
    }
  }

  async function handleCardNumberBlur() {
    console.log("O usuário saiu do campo de número do cartão:", cardNumber);

    window.PagSeguroDirectPayment.getBrand({
      cardBin: cardNumber.substr(0, 6),
      success: function (response) {
        const brand = response.brand;
        console.log("Bandeira do cartão:", brand);
        // Faça o que for necessário com a bandeira do cartão
        setCardBrand(brand.name);
      },
      error: function (error) {
        console.log("Erro ao obter a bandeira do cartão:", error);
      },
    });
  }

  async function createCardToken() {
    return new Promise((resolve, reject) => {
      const creditCardData = {
        cardNumber: cardNumber,
        brand: cardBrand,
        cvv: cardCVV,
        expirationMonth: cardExpirationDateMonth,
        expirationYear: "20" + cardExpirationDateYear,
        success: (response) => {
          const cardToken = response.card.token;
          resolve(cardToken);
        },
        error: (error) => {
          reject(error);
        },
      };

      window.PagSeguroDirectPayment.createCardToken(creditCardData);
    });
  }

  async function handleCheckoutButton() {
    let productsIds = "";
    let customizationsIds = "";

    for (const key in cart) {
      if (Object.hasOwnProperty.call(cart, key)) {
        const element = cart[key];
        if (element.personalize) {
          if (customizationsIds.length > 1) {
            customizationsIds += ",";
          }
          customizationsIds += "Id";
          customizationsIds += "_";
          customizationsIds += element.id;
          customizationsIds += "_";
          customizationsIds += "Quantidade";
          customizationsIds += "_";
          customizationsIds += element.amount;
          customizationsIds += "_";
          customizationsIds += element.nameMark;
          customizationsIds += "_";
          customizationsIds += element.nameModel;
        }

        if (!element.personalize) {
          if (productsIds.length > 1) {
            productsIds += ",";
          }
          productsIds += element.name;
          productsIds += "_";
          productsIds += element.nameMark;
          productsIds += "_";
          productsIds += element.id;
          productsIds += "_";
          productsIds += "Quantidade";
          productsIds += "_";
          productsIds += element.amount;
        }
      }
    }

    try {
      const cardToken = await createCardToken();
      setCardToken(cardToken);

      const response = await api.post(`/users/${user.id}/orders`, {
        addressId,
        customizationsIds: customizationsIds ? customizationsIds : null,
        addressIdDelivery: addressIdDelivery ? addressIdDelivery : addressId,
        productsIds: productsIds ? productsIds : "",
        frete,
        cpf,
        senderHash: senderHash,
        creditCardToken: cardToken,
        subTotal: total,
        total: hasPreviousFreteValue,
        delivery_date: deliveryDate,
        comments: comments ? comments : "Vazio",
        payment_method: "credit_card",
        card_number: cardNumber,
        card_holder_name: cardHolderName,
        card_cvv: cardCVV,
        card_expiration_date: `${cardExpirationDateMonth}${cardExpirationDateYear}`,
      });

      clearCart();

      history.push(`/shopFinish/${response.data.id}`, { order: response.data });
    } catch (e) {
      toast.error("Erro ao validar os dados, verifique-os e tente novamente.");
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      setPagSeguroLoaded(true);

      api
        .post(`/pagseguro/session`)
        .then(({ data }) => {
          console.log(data);
          window.PagSeguroDirectPayment.setSessionId(data);

          window.PagSeguroDirectPayment.getPaymentMethods({
            success: function (response) {
              console.log("oiiiiii2");
              console.log(response.paymentMethods);
            },
            error: function (response) {
              console.log("oiiiiii3");
              console.log(response);
            },
            complete: function (response) {
              // Callback para todas chamadas.
              console.log("oiiiiii4");
              console.log(response);
            },
          });

          setTimeout(() => {
            window.PagSeguroDirectPayment.onSenderHashReady(function (
              response
            ) {
              if (response.status == "error") {
                console.log(response.message);
                return false;
              }
              var hash = response.senderHash; //Hash estará disponível nesta variável.
              setSenderHash(hash);
              console.log("OIIII", hash);
            });
          }, 3000);
        })
        .catch(() => {
          toast.error("Erro ao obter sessão do PagSeguro");
        });
    };

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  useEffect(async () => {
    const response = await api.get("/address");

    setName(response.data.name);
    setSurname(response.data.surname);
    setCnpj(response.data?.cnpj);
    setCpf(response.data.cpf);
    setCep(response.data.cep);
    setStreet(response.data.street);
    setNumber(response.data.number);
    setNeighborhood(response.data.neighborhood);
    setCity(response.data.city);
    setState(response.data.state);
    setPhone(response.data.phone);
  }, [token]);

  useEffect(() => {
    const cepNumber = cep?.replace("-", "").replaceAll("_", "");

    if (80000000 < cepNumber && cepNumber < 83540000) {
      setFreteWithdraw(true);
    } else {
      setFreteWithdraw(false);
    }
  }, [cep]);

  useEffect(async () => {
    const cepNumber = cep?.replace("-", "").replaceAll("_", "");

    if (cepNumber?.length === 8) {
      loadFrete();

      try {
        const response = await cepApi.get(`${cepNumber}/json`);

        if (response.data.erro) {
          toast.error("Cep Invalido");
        } else {
          setStreet(response.data.logradouro);
          setNeighborhood(response.data.bairro);
          setCity(response.data.localidade);
          const state = states.find((state) => state.uf === response.data.uf);
          setState(state.name);
        }
      } catch (e) { }
    }
  }, [cep]);

  useEffect(() => {
    const freteMappings = {
      sedex: "SEDEX",
      ".package": ".Package",
      ".com": ".Com",
      éfácil: "éFácil",
      amanhã: "Amanhã",
      "e-commerce": "e-commerce",
      "pac centralizado": "PAC Centralizado",
      "sedex centralizado": "SEDEX Centralizado",
      express: "Express",
    };

    const selectedFreteInfo = melhorEnvioFretes.find(
      (item) => item.name === freteMappings[deliveryType]
    );

    if (selectedFreteInfo) {
      setDaysFrete(selectedFreteInfo.delivery_time);
      setValueFrete(selectedFreteInfo.price);
    } else {
      setDaysFrete(0);
      setValueFrete(0);
    }
  }, [deliveryType, melhorEnvioFretes, pacFrete]);

  // useEffect(() => {
  //   const date = new Date();

  //   date.setDate(date.getDate() + daysFrete);

  //   const delivery_date = date.toISOString().slice(0, 10);

  //   setDeliveryDate(delivery_date);
  // }, [daysFrete, freteValue]);

  const sortedFretes = melhorEnvioFretes
    .filter((frete) => frete.price) // Filtra os itens com preço
    .sort((a, b) => a.price - b.price);


  return (
    <>
      {user ? (
        <>
          {isCreditCardModalOpened && (
            <>
              <ModalBackground onClick={handleCloseModal} />
              <ModalContainer>
                <Modal>
                  <ModalHeader>
                    <h2>Informe seus dados de cartão</h2>
                    <p>
                      Total a pagar:{" "}
                      <span>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(total)}
                      </span>
                    </p>
                  </ModalHeader>

                  <CreditCard>
                    <CreditCardInformation>
                      <CardNumber>
                        {cardNumber.length === 0
                          ? "**** **** **** ****"
                          : cardNumberFormatted}
                      </CardNumber>

                      <CardHolderName>
                        {cardHolderName.length === 0
                          ? "NOME COMPLETO"
                          : cardHolderName}
                      </CardHolderName>

                      <ExpirationDateContainer>
                        <p>VALIDO ATÉ</p>

                        <span>
                          {cardExpirationDateMonth.length === 0
                            ? "MM"
                            : cardExpirationDateMonth}
                          /
                          {cardExpirationDateYear.length === 0
                            ? "AA"
                            : cardExpirationDateYear}
                        </span>
                      </ExpirationDateContainer>
                    </CreditCardInformation>
                  </CreditCard>

                  <CardForm>
                    <Input
                      id="cardNumber"
                      placeholder="Número do cartão"
                      type="tel"
                      inputmode="numeric"
                      autocomplete="cc-number"
                      maxlength="16"
                      value={cardNumber}
                      onBlur={handleCardNumberBlur}
                      onChange={(e) =>
                        e.target.value.length <= 16 &&
                        setCardNumber(e.target.value)
                      }
                    />
                    <Input
                      id="cardHolderName"
                      placeholder="Nome (igual no cartão)"
                      value={cardHolderName}
                      onChange={(e) => setCardHolderName(e.target.value)}
                    />

                    <InputsContainer>
                      <CardExpirationDate>
                        <Input
                          type="tel"
                          inputmode="numeric"
                          pattern="[0-9\s]{13,19}"
                          id="cardExpirationDateMonth"
                          maxlength="2"
                          placeholder="MM"
                          value={cardExpirationDateMonth}
                          onChange={(e) =>
                            e.target.value.length <= 2 &&
                            setCardExpirationDateMonth(e.target.value)
                          }
                        />
                        <Input
                          type="tel"
                          inputmode="numeric"
                          pattern="[0-9\s]{13,19}"
                          id="cardExpirationDateYear"
                          maxlength="2"
                          id="cardExpirationDate"
                          placeholder="YY"
                          value={cardExpirationDateYear}
                          onChange={(e) =>
                            e.target.value.length <= 2 &&
                            setCardExpirationDateYear(e.target.value)
                          }
                        />
                      </CardExpirationDate>
                      <Input
                        id="cardCVV"
                        placeholder="CVV"
                        maxlength="4"
                        value={cardCVV}
                        onChange={(e) =>
                          e.target.value.length <= 4 &&
                          setCardCVV(e.target.value)
                        }
                      />
                    </InputsContainer>

                    <SubmitButton type="submit" onClick={handleCheckoutButton}>
                      Pagar (
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(total)}
                      )
                    </SubmitButton>
                  </CardForm>
                </Modal>
              </ModalContainer>
            </>
          )}
          <NewHeader title="Finalizar compra" />
          <Title active="Details" />
          <Container>
            <Form>
              <h4>Detalhes de Faturamento</h4>

              <BoxInputDuo>
                <div>
                  <span>Nome: </span>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <span>Sobrenome: </span>
                  <input
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
              </BoxInputDuo>

              <InputSelect>
                <span>Tipo de pessoa:</span>
                <select onChange={(e) => setTypeOfPeople(e.target.value)}>
                  <option value="PF">Pessoa Fisica</option>
                  <option value="PJ">Pessoa Juridica</option>
                </select>
              </InputSelect>

              {typeOfPeople === "PF" ? (
                <BoxInputOne>
                  <span>CPF: </span>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </BoxInputOne>
              ) : (
                <>
                  <BoxInputOne>
                    <span>Nome da empresa: </span>
                    <input
                      type="text"
                      value={namefirm}
                      onChange={(e) => setNameFirm(e.target.value)}
                    />
                  </BoxInputOne>

                  <BoxInputOne>
                    <span>CNPJ: </span>
                    <input
                      type="text"
                      value={cnpj}
                      onChange={(e) => setCnpj(e.target.value)}
                    />
                  </BoxInputOne>
                </>
              )}

              <BoxInputDuo>
                <div>
                  <span>CEP: </span>
                  <InputMask
                    mask="99999-999"
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  />
                </div>
                <div>
                  <span>Rua: </span>
                  <input
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </div>
              </BoxInputDuo>

              <BoxInputDuo>
                <div>
                  <span>Numero: </span>
                  <input
                    type="text"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div>
                  <span>Complemento: </span>
                  <input
                    type="text"
                    value={complement}
                    onChange={(e) => setComplement(e.target.value)}
                  />
                </div>
              </BoxInputDuo>

              <BoxInputDuo>
                <div>
                  <span>Bairro: </span>
                  <input
                    type="text"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                  />
                </div>
                <div>
                  <span>Cidade: </span>
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </BoxInputDuo>

              <InputSelect>
                <span>Estado:</span>
                <select
                  name={state}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {states.map(({ uf, name }) => (
                    <option key={uf} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </InputSelect>

              <BoxInputOne>
                <span>Telefone: </span>
                <InputMask
                  mask="(99) 99999-9999"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </BoxInputOne>

              <BoxInputRadio>
                <input
                  type="radio"
                  value={otherAddressVisible}
                  onClick={() => setOtherAddressVisible(!otherAddressVisible)}
                />
                <span>Entregar para um endereço diferente?</span>
              </BoxInputRadio>

              {otherAddressVisible ? (
                <>
                  <BoxInputDuo>
                    <div>
                      <span>CEP:</span>
                      <input
                        type="text"
                        value={cepDelivery}
                        onChange={(e) => setCepDelivery(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>Rua: </span>
                      <input
                        type="text"
                        value={streetDelivery}
                        onChange={(e) => setStreetDelivery(e.target.value)}
                      />
                    </div>
                  </BoxInputDuo>

                  <BoxInputDuo>
                    <div>
                      <span>Numero: </span>
                      <input
                        type="text"
                        value={numberDelivery}
                        onChange={(e) => setNumberDelivery(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>Complemento: </span>
                      <input
                        type="text"
                        value={complementDelivery}
                        onChange={(e) => setComplementDelivery(e.target.value)}
                      />
                    </div>
                  </BoxInputDuo>

                  <BoxInputDuo>
                    <div>
                      <span>Bairro: </span>
                      <input
                        type="text"
                        value={neighborhoodDelivery}
                        onChange={(e) =>
                          setNeighborhoodDelivery(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <span>Cidade: </span>
                      <input
                        type="text"
                        value={cityDelivery}
                        onChange={(e) => setCityDelivery(e.target.value)}
                      />
                    </div>
                  </BoxInputDuo>

                  <InputSelect>
                    <span>Estado:</span>
                    <select
                      name={stateDelivery}
                      value={stateDelivery}
                      onChange={(e) => setStateDelivery(e.target.value)}
                    >
                      {states.map(({ uf, name }) => (
                        <option key={uf} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </InputSelect>
                </>
              ) : null}

              <BoxInputOne>
                <span>Notas do pedido: </span>
                <textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </BoxInputOne>
            </Form>

            <InfoCart>
              <h4>Seu pedido</h4>
              <InfoTitle>
                <span>Produto</span>
                <span>Subtotal</span>
              </InfoTitle>
              {cart?.map((item) => (
                <Item key={item.id}>
                  <span>
                    {item.name}, {item.nameMark} x{item.amount}
                  </span>
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.subtotal)}
                  </span>
                </Item>
              ))}

              <Item>
                <label>Subtotal</label>
                <span>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(total)}
                </span>
              </Item>
              <FreteItem>
                <span>Frete</span>
                {previousFreteValue > 0 ? (
                  <span className="valueFrete">
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(storedFreteValue)}
                  </span>
                ) : (
                  <span className="valueFrete">
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(selectedShipping.price ?? 0)}
                  </span>
                )}
              </FreteItem>

              {previousFreteValue <= 0 && (
                <MelhorEnvioFreteOptionsWrapper>
                  {sortedFretes.map((frete) =>
                    frete.price ? (
                      <li key={frete.id}>
                        <input
                          type="radio"
                          id={frete.id}
                          name="typeDelivery"
                          value={frete.name}
                          selected={true}
                          onClick={() => {
                            setDeliveryType(frete.name.toLowerCase())
                            setSelectedShipping(frete)
                          }}
                        />
                        <label htmlFor={frete.id}>
                          {frete.name} - {frete.delivery_time} dias -{" "}
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(frete.price)}
                        </label>
                      </li>
                    ) : null
                  )}

                  {freteWithdraw ? (
                    <li>
                      <input
                        type="radio"
                        name="typeDelivery"
                        id="ret"
                        onClick={() => {
                          setDeliveryType("ret")
                          setSelectedShipping({ price: 0 })
                        }}
                      />
                      <label htmlFor="ret">Retirar no local</label>
                    </li>
                  ) : null}
                </MelhorEnvioFreteOptionsWrapper>
              )}

              <Item>
                <label>Total</label>
                {previousFreteValue > 0 ? (
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(Number(total))}
                  </span>
                ) : (
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(Number(total) + Number(selectedShipping.price ?? 0))}
                  </span>
                )}
              </Item>

              <Item className="radioItem">
                <input
                  type="radio"
                  name="typePay"
                  selected={true}
                  id="credit_card"
                  onClick={() => setPaymentMethod("credit_card")}
                />
                <label htmlFor="credit_card">Cartão de credito</label>
              </Item>
              <Item className="radioItem">
                <input
                  type="radio"
                  name="typePay"
                  id="boleto"
                  onClick={() => setPaymentMethod("boleto")}
                />
                <label htmlFor="boleto">Boleto</label>
              </Item>

              <button onClick={() => handleFinalizar()}>
                Finalizar Compra
              </button>

              <p>
                Seus dados pessoais serão usados para processar seu pedido, dar
                suporte à sua experiência em todo este site e para outros fins
                descritos em nossa política de privacidade.
              </p>
            </InfoCart>
          </Container>
        </>
      ) : (
        <PermissionComponent />
      )}
    </>
  );
}
