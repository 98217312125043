import styled from "styled-components";

export const Container = styled.div`
  /* padding: 0 10rem; */
  max-width: 1140px;
  margin: 1.5rem auto;

  display: grid;
  grid-template-columns: 2fr 1.25fr;
  gap: 2rem;

  @media (max-width: 800px) {
    margin: 1rem auto;

    padding: 0 8rem;
  }

  @media (max-width: 600px) {
    padding: 0 2rem;

    grid-template-columns: 1fr;
  }
`;

export const Form = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap");
  border-top: 2px solid #ddd;
  padding: 1.9rem 0 0;
  margin-bottom: 4rem;

  h4 {
    color: #555;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Slabo 27px", serif;
  }

  span {
    color: #222;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }

  input {
    width: 100%;
    height: 2rem;
    border: 1px solid #c1c1c1;
    padding: 0 0.5rem;
  }
`;

export const InfoCart = styled.div`
  height: fit-content;
  border: 2px solid #a16695;
  padding: 30px;

  @media (max-width: 600px) {
    border: 0;
  }

  h4 {
    color: #555;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Slabo 27px", serif;
  }

  .radioItem {
    display: flex;

    input {
      margin-right: 1rem;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 0;
    background-color: #36b5b0;

    margin: 2rem 0;
    padding: 0 1rem;
    height: 2.8rem;

    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    font-size: 0.8rem;
    text-align: justify;
    color: #777;
  }
`;

export const BoxInputDuo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  margin-top: 1rem;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const InputSelect = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;

  select {
    width: 100%;
    height: 2rem;
    border: 1px solid #c1c1c1;
    padding: 0 0.5rem;
  }
`;

export const BoxInputOne = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
    min-height: 5rem;
    border: 1px solid #c1c1c1;
    padding: 0.5rem;
  }
`;

export const BoxInputRadio = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;

  input {
    width: 0.7rem;
    margin-right: 0.5rem;
  }

  span {
    margin: 0;
  }
`;

export const InfoTitle = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid #c1c1c1;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Item = styled.div`
  padding: 1rem 0;
  /* width: 100% !important; */
  border-bottom: 1px solid #ececec;

  display: grid;
  grid-template-columns: 4fr 2fr;
  align-items: center;

  span {
    font-size: 0.9rem;
    color: #777;
    margin-right: 0.5rem;
  }

  label {
    font-size: 0.9rem;
    color: #444;
    margin-right: 0.5rem;
    font-weight: 500;
  }

  label + span {
    text-align: right;
    margin-right: 0;
  }

  span + span {
    text-align: right;
    margin-right: 0;
  }

  strong + span {
    margin-top: 1rem;
  }

  strong + span + strong {
    margin-top: 1rem;
  }

  strong {
    white-space: nowrap;
    color: #111;
    font-weight: 700;
    text-align: right;
  }

  button {
    border: 0;
    margin-top: 1rem;

    background: none;

    text-align: right;
    color: #f09595;
  }
`;

export const FreteItem = styled.div`
  padding: 1rem 0;
  /* width: 100% !important; */
  border-bottom: 1px solid #ececec;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  span {
    font-size: 0.9rem;
    color: #777;
    margin-right: 0.5rem;
  }

  label {
    font-size: 0.9rem;
    color: #444;
    margin-left: 0.25rem;
    font-weight: 500;
  }

  label + input {
    margin-left: 0.5rem;
  }

  label + span {
    text-align: right;
    margin-right: 0;
  }

  span + span {
    text-align: right;
    margin-right: 0;
  }

  strong + span {
    margin-top: 1rem;
  }

  strong + span + strong {
    margin-top: 1rem;
  }

  strong {
    white-space: nowrap;
    color: #111;
    font-weight: 700;
    text-align: right;
  }

  button {
    border: 0;

    background: none;

    text-align: left;
    color: #f09595;
  }

  .valueFrete {
    margin: 0;
  }
`;

export const FreteItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    border: 0;
    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    background: none;

    text-align: left;
    color: #f09595;
  }
`;

export const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  height: 100%;
  width: 380px;
  overflow: auto;

  @media (max-width: 500px) {
    width: 350px;
  }
`;

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 600px;
  z-index: 20;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 60px;

  text-align: center;
  user-select: none;

  h2 {
    font-weight: 400;
    font-size: 24px;
    color: #fff;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #e1e1e1;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #338de8;
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 22px;
    }
  }
`;

export const CreditCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 210px;
  padding: 20px;
  margin: 16px 0 8px;
  border-radius: 12px;
  user-select: none;
  background: rgb(34, 38, 42);
  background: linear-gradient(
    313deg,
    rgba(34, 38, 42, 1) 0%,
    rgba(56, 65, 73, 1) 100%
  );
`;

export const CreditCardInformation = styled.div``;

export const MelhorEnvioFreteOptionsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 0.25rem 0;
  padding: 0;
  gap: 0.25rem;

  & > li {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.25rem;

  }
`;

export const CardNumber = styled.div`
  color: #fff;
  padding-bottom: 8px;
`;

export const CardHolderName = styled.div`
  font-weight: 500;
  color: #626468;
  text-shadow: 0 0 3px #111;
`;

export const ExpirationDateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;

  p {
    width: 50px;
    height: 25px;
    font-size: 14px;
    text-align: right;
    line-height: 1;
    color: #626468;
    text-shadow: 0 0 3px #111;
  }

  span {
    font-size: 15px;
    font-weight: 600;
    color: #626468;
    margin-left: 16px;
    text-shadow: 0 0 3px #111;
  }
`;

export const CardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 100%;
  height: 24px;
  padding: 24px 16px;
  margin: 4px 0;
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  font-size: 15px;
  color: #171717;

  :focus {
    border: 2px solid #338de8;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 50%;
  }

  input {
    width: 49%;
  }
`;

export const CardExpirationDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 49%;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  margin-top: 6px;
  background-color: #1a6ee1;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  color: #fff;
`;
