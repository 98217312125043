import { Router } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import logoWhatsApp from './assets/logo-whatsapp.png';

import history from './services/history';
// import { AcessRoutes } from "./routes/AcessRoutes";
import Global from './styles/Global';
import { Routes } from './routes/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CartContextProvider } from './hooks/useCart';
import { UserContextProvider } from './hooks/useUser';
import {WhatsAppButton} from "./components/WhatsAppButton"
import useCurrentUrl from "./hooks/useCurrentUrl"

Modal.setAppElement('#root');

function App() {
  const currentURL = useCurrentUrl()

  return (
    <UserContextProvider>
      <Router history={history}>
        <CartContextProvider>
          <Global />
          {/* <AcessRoutes /> */}
          <Routes />
          <ToastContainer autoClose={1500} />
          
          {currentURL.includes("user") || currentURL.includes("acess") ? null : <WhatsAppButton/>}
        </CartContextProvider>
      </Router>
    </UserContextProvider>
  );
}

export default App;
