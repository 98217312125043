import { useState } from 'react';
import { FormModal } from '../../../../components/Modal/FormModal';
import { ButtonsContainer, FieldInput } from '../../../../components/Modal/FormModal/styles';
import api from '../../../../services/api';
import history from '../../../../services/history';

export function NewBanner({ isOpen, onRequestClose }) {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState(null);
  const [ismobile, setIsMobile] = useState(false);


  async function handleCreateBanner(e) {
    e.preventDefault();
    if (!title || !link || !image) {
      return alert("Preencha todos os campos");
    }
    const data = new FormData();
    data.append('title', title);
    data.append('link', link);
    data.append('image', image);
    data.append('ismobile', ismobile);

    
    try {
      const response = await api.patch('banners', data).catch((e) =>{
				console.log(e)
				console.log(e.response)
			})
      setTitle("");
      setLink("");
      setImage(null);
      setIsMobile(false);
      history.go('/access/banners');
    } catch (error) {
      alert(error.response.data.error);
    }
  }
    

  return (
    <FormModal isOpen={isOpen} onRequestClose={onRequestClose} title="Novo banner">
      <FieldInput>
        <label>Título</label>
        <input 
          type="text" 
          placeholder="Título do banner" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)}
        />
      </FieldInput>

      <FieldInput>
        <label>Link</label>
        <input 
          type="text" 
          placeholder="Link do banner" 
          value={link} 
          onChange={(e) => setLink(e.target.value)}
        />
      </FieldInput>

      <FieldInput>
        <label>Imagem para seu banner</label>
        <input type="file" className="file" onChange={(e) => setImage(e.target.files[0])}/>
      </FieldInput>

      <FieldInput style={{ display: "inline-block", alignItems: "center" }}>
				<label style={{ marginRight: "10px", position: "relative", top: "-16px" }}>É Mobile?</label>
				<input type="checkbox" style={{    display: "inline-flex", width: "17px" }} checked={ismobile} onChange={(e) => setIsMobile(e.target.checked)} />
			</FieldInput>

      <ButtonsContainer>
        <button type="submit" onClick={(e) => handleCreateBanner(e)}>Adicionar banner</button>
      </ButtonsContainer>
    </FormModal>
  );
}
