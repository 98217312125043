import styled from 'styled-components';

export const CasePreview = styled.div`
  width: 247px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;

    width: 100%;

    margin-left: 0px;
  }
`;

export const BackgroundName = styled.h2`
  text-align: center;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-top: 20px;
  }
`;
