import styled from 'styled-components';

export const DeliveryContainer = styled.form`
  padding: 1rem 0;
  width: 100%;
  border-bottom: 1px solid #ececec;

  div {
    margin-bottom: 1rem;

    label {
      margin-left: 0.5rem;
    }

    label + input {
      margin-top: 0.75rem;
    }
  }

  .text-adress {
    font-size: 13px;
    margin-left: 10px;
  }

  .inputCEP {
    width: 100%;
    padding: 0.5rem;
    height: 2rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    /* background-color:#e7e9ee; */

    font-weight: 400;
    font-size: 0.8rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 1rem;
    }
  }

  button {
    width: 100%;

    border: 0;
    margin-top: 1rem;

    background: none;

    text-align: right;
    color: #f09595;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex
`

export const MelhorEnvioFreteOptionsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 0.25rem 0;
  padding: 0;
  gap: 0.25rem;

  & > li{
    list-style: none;
  }
`