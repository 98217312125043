import { useState, useEffect } from 'react';
import history from '../services/history';



function useCurrentUrl() {
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentUrl(window.location.href);
    };

    const unlisten = history.listen(handleLocationChange);

    return () => {
      unlisten(); // Remove o listener do histórico quando o componente desmontar
    };
  }, []); // Array de dependência vazio para executar o efeito apenas uma vez

  return currentUrl;
}

export default useCurrentUrl;