import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const TooltipBase = ({id, title, children, disabled = true}) => {
    return (
        <>
            {(disabled) ? (
                <>
                    {children}
                </>
            ) : (
                <OverlayTrigger 
                    placement='right'
                    overlay={
                        <Tooltip 
                        id={id}
                        >
                            {title}
                        </Tooltip>
                    }
                >
                    {children}
                </OverlayTrigger>
            )}
        </>
    )
}