import { Content } from './styles';

export const CustomizeFull = () => {
  return (
    <Content>
      <h2>Olha como sua capinha ficou!</h2>
    </Content>
  );
}

