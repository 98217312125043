import { useEffect, useState } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { NewHeader } from '../../../components/NewHeader';
import { useUser } from '../../../hooks/useUser';
import api from '../../../services/api';
import Title from '../Title'
import { Container, InfoOrder, ButtonContainer } from './styles';

export function FinishBuy(props) {
  const id = props.match.params.id;

  const [link, setLink] = useState("");
  const [order, setOrder] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');

  const { token } = useUser()

  async function handlePay(){
      const response = await api.get(`/orders/single/${id}`);
      setOrder(response.data);
      setPaymentMethod(response.data.payment_method);
      if (response.data.payment_method === 'boleto') {
        setLink(response.data.boleto_url);
      }
  }

  useEffect(()=> {
    api.defaults.headers.authorization = token;
    handlePay()
  }, [token])

  return (
    <>
      <NewHeader />
      <Title active="End"/>
      
      <Container>
        <h1>Pedido realizado com sucesso</h1>
        
        <InfoOrder>
          <h3>Dados do pedido</h3>
          <div>
            <strong>Id</strong>
            <span>#{order.id}</span>
            <strong>Status</strong>
            <span>{order.status}</span>
            <strong>Nome</strong>
            <span>{order.address?.name}</span>
            <strong>Email</strong>
            <span>{order.user?.email}</span>
            <strong>Totall</strong>
            <span>
              {
                new Intl.NumberFormat('pt-BR',{
                  style: "currency",
                  currency:"BRL"
                }).format(order.total)
              }
            </span>
            <strong>Metodo de pagamento</strong>
            <span>{order.payment_method === 'boleto' ? 'Boleto Bancario' : 'Cartão de crédito'}</span>
          </div>
        </InfoOrder>

        <ButtonContainer>
          <a href="/user">
            Minha conta
            <BsArrowRightShort color="#fff" size={24}/>
          </a>
          {paymentMethod === 'boleto' && (
            <a style={{ marginLeft: 16 }} href={link} target="_blank">
              Pagar
              <BsArrowRightShort color="#fff" size={24}/>
            </a>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}