import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { DeliveryContainer, MelhorEnvioFreteOptionsWrapper } from "./styles";
import { Spinner } from "react-bootstrap";
import { TooltipBase } from "../ToolTip";
import MelhorEnvioSdk from "melhor-envio";

export const Delivery = ({
  setValueFrete,
  setDaysFrete,
  isButtonEnable,
  product,
  cepDelivery,
  onSelectShipping
}) => {
  const [freteWithdraw, setFreteWithdraw] = useState(false);
  const [selectTypeDelivery, setSelectTypeDelivery] = useState("");
  const [cep, setCep] = useState("");
  const [melhorEnvioFretes, setMelhorEnvioFretes] = useState([]);
  const [pacFrete, setPacFrete] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const me = new MelhorEnvioSdk({
    sandbox: false,
  });

  async function callMelhorEnvio() {
    try {
      const response = await api.post('/freight', { cep })
      console.log(response)
      setMelhorEnvioFretes(response.data.map((service) => {
        service['name'] = `${service['company']['name']} ${service['name']}`
        return service;
      }));
    } catch (err) {
      console.log(err);
    }
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  async function loadFrete(e) {
    e.preventDefault();

    setIsLoading(true);

    const validationOne = cep.length === 8;
    const validationTwo = isNumber(cep);

    if (!validationOne) {
      setIsLoading(false);
      return alert("Erro na validação");
    }

    if (!validationTwo) {
      setIsLoading(false);
      return alert("Erro na validação T");
    }

    if (selectTypeDelivery === "ret") {
      setValueFrete(0.00001);
      setDaysFrete(0.00001);
    } else {
      // const sedexResponse = await api.post("melhor-envio", {
      //   cep: cep,
      //   option: "04014",
      // });

      const pacResponse = await api.post("correios", {
        cep: cep,
        option: "04510",
      });

      callMelhorEnvio();

      setPacFrete({
        days: pacResponse.data.days,
        value: pacResponse.data.value,
      });
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (80000000 < cep && cep < 83540000) {
      setFreteWithdraw(true);
    } else {
      setFreteWithdraw(false);
    }
  }, [cep]);


  const sortedFretes = melhorEnvioFretes
    .filter((frete) => frete.price)
    .sort((a, b) => a.price - b.price);

  console.log(sortedFretes);

  return (
    <DeliveryContainer>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div>
            <MelhorEnvioFreteOptionsWrapper>
              {sortedFretes.map((frete) =>
                frete.price ? (
                  <li key={frete.id}>
                    <input
                      type="radio"
                      id={frete.id}
                      name="type"
                      value={frete.name}
                      selected={true}
                      onClick={() => {
                        // setSelectTypeDelivery(frete.name.toLowerCase());
                        onSelectShipping(frete);
                      }}
                    />
                    <label htmlFor={frete.id}>
                      {frete.name} - {frete.delivery_time} dias -{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(frete.price)}
                    </label>
                  </li>
                ) : null
              )}
            </MelhorEnvioFreteOptionsWrapper>

            {freteWithdraw ? (
              <>
                <input
                  type="radio"
                  id="ret"
                  name="type"
                  value="ret"
                  onClick={() => {
                    setSelectTypeDelivery("ret")
                    onSelectShipping({ price: 0 })
                  }
                  }
                />
                <label htmlFor="ret">
                  Retirar no local
                  {selectTypeDelivery === "ret" ? (
                    <span className="text-adress">
                      (Des. Westphalen, 1949 - Centro, Ctba/PR)
                    </span>
                  ) : (
                    ""
                  )}
                </label>
                <br />
              </>
            ) : null}
          </div>

          <input
            className="inputCEP"
            type="text"
            placeholder="Digite seu CEP (Apenas numeros)"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
          />
          <TooltipBase
            title="Selecione um modelo para calcular o frete"
            disabled={isButtonEnable}
          >
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                onClick={(e) => loadFrete(e)}
                disabled={!isButtonEnable}
              >
                Calcular
              </button>
            </div>
          </TooltipBase>
        </>
      )}
    </DeliveryContainer>
  );
};
Delivery.defaultProps = {
  setValueFrete: () => { },
  setDaysFrete: () => { },
  isButtonEnable: false,
};
