import { useEffect, useState } from "react";
import { FormModal } from "../../../../components/Modal/FormModal";
import { ButtonsContainer, FieldInput } from "../../../../components/Modal/FormModal/styles";
import api from "../../../../services/api";
import history from "../../../../services/history";

export function EditBanner({ isOpen, onRequestClose, banner, url, isMobile }) {
  const [name, setName] = useState("")
  const [file, setFile] = useState(null)
	 const [link, setLink] = useState("");
	 const [ismobile, setIsMobile] = useState("");
   
  async function handleUpdateBanner(e){
    e.preventDefault()

    if(file){
      const data = new FormData();
      data.append('image', file);

      try {
        const response = await api.put(`banners/${banner.id}`, data).catch(
					(e)=>{
						console.log('error', e)
						console.log('error', e.response)
					}
				)
        console.count(response)
        
      } catch (error) {
        console.log(error.response.data.error)
      }
    }

    try {
      const response = await api.put(`banners/put/${banner.id}`, { name, link, ismobile }).catch((e) =>{
				console.log(e)
				console.log(e.response)
			})
      console.count(response)
      history.go("/acess/banners")
      
    } catch (error) {
      console.log(error.response.data.error)
    }
  }

    useEffect(() => {
      setName(banner.name)
			setLink(banner.link)
			setIsMobile(banner.ismobile)
    }, [banner])
    

    return (
        <FormModal isOpen={isOpen} onRequestClose={onRequestClose} title="Editar banner">
        <FieldInput>
          <label>Nome</label>
          <input 
            type="text" 
            placeholder="Nome do banner" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FieldInput>

        <FieldInput>
          <label>Troque a imagem do seu banner</label>
          <input type="file" className="file" onChange={(e) => setFile(e.target.files[0])}/>
        </FieldInput>

        {/* new inputs for URL and isMobile */}
        <FieldInput>
          <label>URL do Banner</label>
          <input 
            type="text" 
            placeholder="https://www.example.com" 
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </FieldInput>

        <FieldInput>
          <label>O banner deve ser exibido somente em dispositivos móveis?</label>
          <input type="checkbox" checked={isMobile} onChange={(e) => setIsMobile(e.target.checked)}/>
        </FieldInput>

        <ButtonsContainer>
          <button type="submit" onClick={handleUpdateBanner}>Alterar banner</button>
        </ButtonsContainer>
      </FormModal>
    );
}
